import React, {useEffect} from "react";
import { connect } from "react-redux";
import { useLocation } from "@reach/router";
import { StaticImage } from "gatsby-plugin-image"
import Breadcrumbs from "../breadcrumbs";

import AOS from 'aos';
import 'aos/dist/aos.css';
import { graphql, useStaticQuery } from 'gatsby';
import img from '../../images/banner-about.jpg'

import { getBrText } from "../../helpers/helper.rendering";
import { Link } from "@reach/router";

const Main = ({actions}) => {

    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            name
            about_us
            sections{
                about{
                    title
                    slogan
                    sup_title
                    button{
                        value
                        link
                    }
                    history{
                        title
                        subtitle
                        paragraph
                        extra
                    }
                }
            }
        }
    }`)
    
    useEffect(() => {
        AOS.init({ duration : 2000});
    }, [])

    const location = useLocation()

    useEffect(() => {
        if(location.pathname.includes('/nosotros')) {
            checkActionsTop()
        }
    },[location])

    const checkActionsTop = () => {
        if (actions && actions.length > 0) {
            const filteredActions = actions.filter(a => a.small_type === "VEN" || a.small_type === "IEN" || a.small_type === "SIE").map(a => [a.section, a.small_type].join())
            // console.log(filteredActions); - // ['Nosotros,VEN']
            if (filteredActions.length > 0) {
                const filteredbyPage = filteredActions.filter(p => p.includes("Nosotros"))
                return (filteredbyPage.length > 0)
                    ? ((filteredbyPage[0].split(',')[0] === "Nosotros") || null) : null
            }
        } else {
            return null
        }
    }

    return (
        <div className={"h-auto m-0 " + (checkActionsTop() ? "pt-5" :  "pb-4")}>
            { !checkActionsTop()
                ? (<section className="about-main">
                    <div className="banner-about">
                        <img src={img} alt={realEstate.sections.about.title} />
                        <div className="container-fluid ">
                            <div className="row content-main d-flex align-items-center justify-content text-center">
                                <div className="col-12" style={{zIndex:"1"}}>
                                    <h1>
                                        {realEstate.sections.about.slogan[0]}
                                        <br className="d-block d-lg-none"/>
                                        {realEstate.sections.about.slogan[1]}
                                        <br className="d-none d-lg-block"/>
                                        {realEstate.sections.about.slogan[2]}
                                        <br className="d-block d-lg-none"/>
                                        {realEstate.sections.about.slogan[3]}
                                    </h1>
                                    <h4>{realEstate.sections.about.title} <br />{realEstate.sections.about.sup_title} </h4>
                                    <a className="button-slider" href="#about-history"> <i className="icon-arrow-down"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>) : null }
        </div>
    )
} 

export default connect(state => ({
    actions: state.actions.actions,
}),null)(Main);