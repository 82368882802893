import React, {useEffect} from "react";
import { StaticImage } from "gatsby-plugin-image"

import AOS from 'aos';
import 'aos/dist/aos.css';
import { graphql, useStaticQuery } from 'gatsby';
import img1 from '../../images/about-1.webp'
import img2 from '../../images/about-2.webp'
import Breadcrumbs from "../breadcrumbs";



import { getBrText } from "../../helpers/helper.rendering";

const History = () => {
    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            name
            sections{
                about{
                    title
                    button{
                        value
                        link
                    }
                    history{
                        title
                        subtitle
                        paragraph
                        extra
                    }
                }
            }
        }
    }`)
    return (
        <section id="about-history" className="about-history">
            <div className="container-fluid" id="checkContainer">
                <div className="row">
                    <div className="col-12 mt-4">
                        <Breadcrumbs
                                props={[
                                { name: realEstate.name, route: "/", location: "" },
                                { name: "Nosotros", route:   "", location: ""},
                                ]}
                            />
                    </div>
                    <div className="col-12 text-lg-center">     
                        <div className="row article-history">
                            <div data-aos="fade-right" className="col-12 d-lg-flex align-items-strech justify-content-center">
                            <section className="about-history">
                                <div className="container" id="checkContainer">
                                    <div className="row content-history">
                                        <div className="row w-100">
                                            <div className="col-12 d-lg-flex align-items-strech">
                                                <div className="col-lg-6">
                                                    <img src={img1} alt=""/>
                                                </div>
                                                <div className="col-lg-6 text-start text-content">
                                                    <p className="ps-lg-5 mb-5 section-name">{realEstate.sections.about.title}</p>
                                                    <h4 className="ps-lg-5 mb-5">{realEstate.sections.about.history.title}</h4>
                                                    <p className="ps-lg-5">
                                                        {realEstate.sections.about.history.paragraph[0]}
                                                            <br /> <br />
                                                        {realEstate.sections.about.history.paragraph[1]}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row content-history justify-content-lg-center">
                                        <div class="col-12 d-flex align-items-stretch">
                                            <div class="col-lg-6 d-lg-flex flex-lg-column text-start text-content">
                                                <img class="d-block d-lg-none my-5" src={img2} alt=""/>
                                                <p class="ps-lg-5 mb-5 section-name">{realEstate.sections.about.title}</p>
                                                <h4 class="ps-lg-5 mb-5">{realEstate.sections.about.history.subtitle}</h4>
                                                <p className="ps-lg-5">
                                                    {realEstate.sections.about.history.paragraph[2]}
                                                        <br/><br/>
                                                    {realEstate.sections.about.history.paragraph[3]}
                                                </p>
                                            </div>
                                            <div class="col-lg-6 d-none d-lg-block ps-lg-4">
                                                <img src={img2} alt=""/>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row extra-history justify-content-center">
                                        <div class="col-12 col-lg-8 text-center">
                                            <p>
                                                {realEstate.sections.about.history.extra[0]}
                                                <br />
                                                {realEstate.sections.about.history.extra[1]}
                                            </p>
                                            <a href={realEstate.sections.about.button.link} class=" btn-global btn-secondary">{realEstate.sections.about.button.value}</a>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default History